/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import React, { FC, Fragment, useMemo } from 'react';
import { MdCreate } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { FaEyeSlash } from 'react-icons/fa';

import { Box, Flex } from 'components/styled';
import Text from 'components/styled/Text';
import Link, { HrefLink } from 'components/styled/Link';
import { ItemLabelList } from 'components/styled/ItemLabel';
import AspectRatio from 'components/styled/AspectRatio';
import FloatingActionsButton from 'components/floatingactions/FAButton';
import SubButton from 'components/floatingactions/SubButton';

import CompanyEventActivateDialog from 'modules/companyEvents/new/CompanyEventActivateDialog';

import { Theme } from 'theme';

import logoPlaceholder from 'assets/company-avatar-placeholder.png';

import { getFile } from 'api/fileApi';

import useToggleCompanyEventActivate from 'hooks/useToggleCompanyEventActivate';
import useBreakpoint from 'hooks/useBreakpoint';

import { getAddressLabel } from 'utils/address';
import { getFormattedEventDate, isEventActive } from 'utils/UASHelpers';

import { CompanyEvent } from 'typing/endpoints';

type Props = CompanyEvent & {
	editable?: boolean;
	embed?: boolean;
	carousel?: boolean;
	dashboard?: boolean;
};

const LinkEmbed: FC<{
	id: string;
	embed?: boolean;
	path: string;
}> = ({ id, embed, children, path }) => {
	if (embed) {
		return (
			<HrefLink href={`https://kportal.vsb.cz/${path}/${id}`} target="_blank">
				{children}
			</HrefLink>
		);
	}
	return <Link to={`/${path}/${id}`}>{children}</Link>;
};

const CompanyEventTile: FC<Props> = ({
	editable,
	embed,
	carousel,
	dashboard,
	...companyEvent
}) => {
	const {
		title,
		address,
		company,
		businessSectors,
		from,
		to,
		photo,
		isVisible,
		urlClickCounter,
		activateFrom,
		activeTo,
		id,
		isOnline,
	} = companyEvent;

	const eventPhoto = useMemo(() => getFile(photo) ?? logoPlaceholder, [photo]);
	const companyLogo = useMemo(() => getFile(company?.logo) ?? logoPlaceholder, [
		company?.logo,
	]);
	const { push } = useHistory();

	const toggleEventActivate = useToggleCompanyEventActivate(id);
	const isMobile = useBreakpoint(0);
	const isActive = isEventActive(companyEvent);
	const aFrom = isActive
		? new Date(activateFrom).toLocaleDateString('cs')
		: undefined;
	const aTo = isActive
		? new Date(activeTo).toLocaleDateString('cs')
		: undefined;
	return (
		<AspectRatio ratio={[3, 1]} width={1}>
			<Flex
				flexDirection="column"
				height="100%"
				bg="white"
				boxShadow={`0px 0px 12px rgba(0, 0, 0, 0.1)`}
			>
				<Flex
					alignItems="flex-end"
					justifyContent="space-between"
					backgroundColor="grey"
					height={carousel ? '40%' : 200}
					minHeight={100}
					color="white"
					width={1}
					position="relative"
					css={(theme: Theme) => css`
						background: url(${eventPhoto ?? ''});
						background-size: cover;
						background-position: center;
						border-bottom: 1px solid ${theme.colors.darkerGrey};
					`}
				>
					{editable && (
						<Fragment>
							<Box
								px={2}
								bg={
									// eslint-disable-next-line no-nested-ternary
									isActive
										? isVisible
											? 'primary'
											: 'secondary'
										: 'darkerGrey'
								}
								boxShadow={`1px 0px 4px rgba(0,0,0,0.5)`}
							>
								Zveřejněná:{' '}
								<b>
									{
										// eslint-disable-next-line no-nested-ternary
										isActive
											? isVisible
												? `${aFrom} - ${aTo}`
												: `${aFrom} - ${aTo}`
											: 'Ne'
									}
								</b>{' '}
							</Box>
							<Box
								px={2}
								bg="warning"
								boxShadow={`-1px 0px 4px rgba(0,0,0,0.5)`}
							>
								Kliknutí: <b>{urlClickCounter}</b>
							</Box>
						</Fragment>
					)}
				</Flex>
				<Flex
					flexDirection="column"
					alignItems="flex-start"
					flexGrow={1}
					position="relative"
					width={1}
					px={25}
					pt={20}
				>
					{' '}
					<Flex width={1} justifyContent="space-between">
						<LinkEmbed id={id} embed={embed} path="company-event">
							<Flex
								width={1}
								justifyContent="space-between"
								alignItems="flex-start"
							>
								<Flex flexDirection="column" mr={3} alignItems="flex-start">
									<Text fontWeight="bold" my={0} fontSize="lg">
										{title}
									</Text>
								</Flex>
							</Flex>
						</LinkEmbed>
						{!isMobile && !dashboard && (
							<Box position="relative" zIndex={0}>
								<AspectRatio
									ratio={[1, 1]}
									height={100}
									width={100}
									maxHeight={100}
									flexShrink={0}
									ml={-90}
									css={css`
										background-image: url(${companyLogo});
										background-size: contain;
										background-repeat: no-repeat;
										background-position: center;
										border-radius: 50%;
										position: absolute;
									`}
								/>
							</Box>
						)}
					</Flex>
					<Flex width={1} justifyContent="space-between">
						<Box py={2} zIndex={1}>
							<Text my={0} fontSize="sm">
								Datum začátku akce: <br />
								<b>{getFormattedEventDate(from)}</b>
							</Text>
							<Text my={0} fontSize="sm">
								Datum konce akce: <br />
								<b>{getFormattedEventDate(to)}</b>
							</Text>
						</Box>
					</Flex>
					<Box flexGrow={1} />
					<Flex flexWrap="wrap">
						{isOnline ? (
							<>
								Místo konání:&nbsp;<b>online</b>
							</>
						) : (
							getAddressLabel(address)
						)}
						<Flex width="100%">
							{businessSectors && businessSectors.length > 0 && (
								<ItemLabelList>
									{businessSectors.map(b => b?.name)}
								</ItemLabelList>
							)}
						</Flex>
					</Flex>
				</Flex>
				<Flex flexGrow={1} />

				<Flex
					py={1}
					px={25}
					alignItems="center"
					justifyContent={editable ? 'space-between' : 'flex-end'}
				>
					{company && (
						<LinkEmbed id={company?.id} embed={embed} path="company">
							<Flex alignItems="center">
								<Text fontSize="md">{company?.name ?? ''}</Text>
							</Flex>
						</LinkEmbed>
					)}
					{editable && (
						<Box>
							<FloatingActionsButton id={`${id}-card-actions`}>
								<SubButton
									icon={<MdCreate size={15} color="white" />}
									size={35}
									tooltip="Upravit"
									onClick={() => push(`/company-event/edit/${id}`)}
									backgroundColor="primary"
								/>
								{!activateFrom && (
									<CompanyEventActivateDialog
										{...companyEvent}
										isSubbutton
										afterSubmit={() => null} //TODO:
									/>
								)}
								{activateFrom && (
									<SubButton
										icon={<FaEyeSlash size={15} color="white" />}
										size={35}
										tooltip="Deaktivovat"
										onClick={() => toggleEventActivate()}
										backgroundColor="error"
									/>
								)}
							</FloatingActionsButton>
						</Box>
					)}
				</Flex>
			</Flex>
		</AspectRatio>
	);
};
export default CompanyEventTile;
