export default {
	// Not found
	NotFound: () => import('modules/notFound'),

	// Public
	Homepage: () => import('modules/public/homepage'),
	Conditions: () => import('modules/public/conditions'),
	HowItWorksStudent: () =>
		import('modules/public/howitworks/HowItWorksStudent'),
	HowItWorksAbsolvent: () =>
		import('modules/public/howitworks/HowItWorksAbsolvent'),
	HowItWorksCompany: () =>
		import('modules/public/howitworks/HowItWorksCompany'),
	Login: () => import('modules/public/login'),
	LoginRedirect: () => import('modules/public/loginRedirect'),
	ForgottenPassword: () => import('modules/public/forgottenPassword'),
	LoginResetAttempts: () => import('modules/public/login/ResetAttempts'),
	ExternalLogin: () => import('modules/public/login/external'),
	GraduateRegistration: () => import('modules/graduate/registration'),
	GraduateRegistrationDone: () =>
		import('modules/graduate/registration/forms/RegistrationDone'),
	ResetPassword: () => import('modules/public/resetPassword'),
	PublicCompanyProfile: () =>
		import('modules/public/companies/PublicCompanyProfile'),
	PublicCompaniesList: () =>
		import('modules/public/companies/PublicCompaniesList'),
	PublicOffersList: () => import('modules/public/offers/PublicOffersList'),
	PublicEvents: () => import('modules/public/events/'),
	PublicOfferDetail: () => import('modules/public/offers/PublicOfferDetail'),
	EmbedOffersCarousel: () => import('modules/public/embed/EmbedOffersCarousel'),

	// Company
	CompanyDashboard: () => import('modules/company/dashboard'),
	CompanyOwnOffers: () => import('modules/company/companyOwnOffers'),
	CompanySearchOffers: () => import('modules/company/offers'),
	CompanySearchCompanyEvents: () =>
		import('modules/company/company-events/search'),
	CompanySearchCompanies: () => import('modules/company/companies/search'),
	CompanyOrders: () => import('modules/company/orders'),
	CompanyRegistration: () => import('modules/company/registration'),
	CompanyRegistrationDone: () => import('modules/company/registration/done'),
	CompanySettings: () => import('modules/company/settings'),
	CompanyProfileEdit: () => import('modules/company/profile/edit'),
	CompanySearchAlgoStudents: () =>
		import('modules/company/students/search-algo'),
	CompanyOrdersNew: () => import('modules/company/orders/new'),
	CompanyEventsNew: () => import('modules/companyEvents/new'),
	CompanyOwnEvents: () => import('modules/companyEvents/companyOwnEvents'),
	CompanyNotifications: () => import('modules/company/notifications'),

	// Student
	StudentDashboard: () => import('modules/student/dashboard'),
	Student: () => import('modules/student/profile'),
	StudentREDESIGN: () => import('modules/student/profileREDESIGN'),
	StudentProfileEditREDESIGN: () => import('modules/student/profileREDESIGN/'),
	StudentProfileEdit: () => import('modules/student/profile/edit'),
	ConsentGDPR: () => import('modules/student/gdpr'),
	StudentSettings: () => import('modules/student/settings'),
	StudentIntroductionForm: () => import('modules/student/introductionForm'),
	StudentSearchCompanies: () =>
		import('modules/search/companies/StudentSearchCompanies'),
	StudentMyReactions: () =>
		import('modules/student/dashboard/DashboardReactions'),
	StudentNotifications: () =>
		import('modules/student/dashboard/DashboardNotifications'),
	StudentMyOffers: () =>
		import('modules/student/dashboard/DashboardSavedOffers'),

	StudentMyCompanies: () =>
		import('modules/student/dashboard/DashboardCompanies'),
	StudentsToStudents: () => import('modules/search/students-to-students'),

	// Admin
	AdminDashboard: () => import('modules/admin/dashboard'),
	AdminSearchOffers: () => import('modules/admin/offers'),
	AdminSearchCompanies: () =>
		import('modules/search/companies/AdminSearchCompanies'),
	AdminSearchCompanyEvents: () => import('modules/admin/company-events/search'),

	AdminOrders: () => import('modules/admin/orders'),
	AdminStatistics: () => import('modules/admin/statistics'),
	AdminProducts: () => import('modules/admin/products'),
	AdminSettings: () => import('modules/admin/settings'),

	// Shared
	CompanyProfile: () => import('modules/company/profile'),
	PopupRedirect: () => import('modules/popups/popup-redirect'),

	Offer: () => import('modules/offer/detail'),
	OfferNew: () => import('modules/offer/new'),
	OfferEdit: () => import('modules/offer/edit'),
	OfferCandidates: () => import('modules/offer/candidates'),
	SearchStudents: () => import('modules/search/students'),
	SearchOffers: () => import('modules/search/offers'),
	OffersRouteByUser: () => import('modules/search/offers/OffersRouteByUser'),
	SearchCompanyEvents: () => import('modules/search/company-events'),
	CompanyEventDetail: () => import('modules/companyEvents/detail'),
	CompanyEventEdit: () => import('modules/companyEvents/edit'),
};
